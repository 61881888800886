<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
      :breadcrumbs="banApp.crumbs"
    />
    <land-section
      id="activity-detail"
      space="40"
    >
      <v-container
        style="max-width: 800px;"
      >
        <v-row
          no-gutters
          justify="center"
        >
          <v-col
            cols="12"
          >
            <div class="d-flex flex-column">
              <v-card
                flat
                style="border: 1px solid #CFD8DC; background-color: white;"
              >
                <div class="d-flex flex-column justify-center align-center px-8 pt-6">
                  <span class="text-h6">{{ actInfo.title }}</span>
                  <span class="text-body-1 blue-grey--text darken-4 mt-1">{{ actInfo.timelimit }}</span>
                </div>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pa-6"
                >
                  <v-text-field
                    v-model="wdName.value"
                    :counter="wdName.counter"
                    :label="wdName.label"
                    :hint="wdName.hint"
                    :rules="wdName.rules"
                    required
                    persistent-hint
                    outlined
                  />
                  <v-text-field
                    v-model="wdPhone.value"
                    :counter="wdPhone.counter"
                    :label="wdPhone.label"
                    :hint="wdPhone.hint"
                    :rules="wdPhone.rules"
                    required
                    persistent-hint
                    outlined
                    class="mt-2"
                  />
                  <v-textarea
                    v-model="wdRemark.value"
                    :counter="wdRemark.counter"
                    :label="wdRemark.label"
                    :hint="wdRemark.hint"
                    :rules="wdRemark.rules"
                    persistent-hint
                    outlined
                    class="mt-2"
                  />
                  <land-file-input
                    :label="wdFiles.label"
                    :hint="wdFiles.hint"
                    :accept="wdFiles.accept"
                    :multiple="wdFiles.multiple"
                    :rules="wdFiles.rules"
                    pattern="mt-2"
                  />
                </v-form>
              </v-card>

              <div class="d-flex flex-column justify-center align-center mt-6">
                <v-btn
                  rounded
                  depressed
                  color="primary"
                  width="400"
                  height="48"
                  :disabled="btnSubmit.disabled"
                  :loading="btnSubmit.loading"
                  @click="submit"
                >
                  {{ btnSubmit.text }}
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>

    <land-alert :ref="alertName" />
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import lib from '@/api/co.lib'

  import mixAlert from '@/pages/mixins/mix.alert.js'
  import mixHomeBanner from '@/pages/mixins/home/mix.home.banner'
  import mixActivityDetail from '@/pages/mixins/activity/mix.activity.detail'

  export default {
    metaInfo: { title: '项目申报' },
    mixins: [
      mixAlert,
      mixHomeBanner,
      mixActivityDetail
    ],
    data () {
      return {
      }
    },
    created () {
      const name = api.page.activityDetail.name
      const params = JSON.parse(lib.cookie.get(name))

      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()

      const actId = params.activityId || ''
      if (actId) {
        this.getActivity(actId)
      }
    }
  }
</script>
